import React from "react"
import { Col, Container, Row } from "react-awesome-styled-grid"
import { LinkCta } from "../components/Button"
import DivHelper from "../components/DivHelper"
import { Heading } from "../components/Heading"
import Layout from "../components/Layout"

const NotFound = () => {
  return (
    <Layout>
      <Container>
        <Row>
          <Col>
            <DivHelper center>
              <Heading>Seite nicht gefunden</Heading>
            </DivHelper>
            <DivHelper mt={40} center>
              <LinkCta to="/">Zur Startseite</LinkCta>
            </DivHelper>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default NotFound
